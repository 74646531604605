<template>
  <footer class="footer">
    <p><router-link class="adminLink" to="/admin">&copy; 2024 Bali Solarium</router-link>. Todos los derechos reservados.</p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  text-align: center;
  padding: 1rem;
  background-color: #333;
  color: #fff;
  width: 100%;
}

.adminLink {
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
}

.adminLink:hover {
  text-decoration: underline;
}
</style>
