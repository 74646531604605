import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomePage.vue";
import AdminLogin from "../views/AdminLogin.vue";
import AdminDashboard from "../views/AdminDashboard.vue";
import AdminAddProduct from "../views/AdminAddProduct.vue";
import AdminEditProducts from "../views/AdminEditProducts.vue";
import AdminSales from "../views/AdminSales.vue";
import PagoAprobado from "../views/PagoAprobado.vue";
import PagoRechazado from "../views/PagoRechazado.vue";
import TodosProductos from "../views/TodosProductos.vue";
import AuthCheckout from "../views/AuthCheckout.vue";
import SesionesView from "../views/SesionesView.vue";

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/admin", name: "AdminLogin", component: AdminLogin },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/add-product",
    name: "AdminAddProduct",
    component: AdminAddProduct,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/edit-products",
    name: "AdminEditProducts",
    component: AdminEditProducts,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/sales",
    name: "AdminSales",
    component: AdminSales,
    meta: { requiresAuth: true },
  },
  { path: "/pago-aprobado", name: "PagoAprobado", component: PagoAprobado },
  { path: "/pago-rechazado", name: "PagoRechazado", component: PagoRechazado },
  { path: "/productos", name: "TodosProductos", component: TodosProductos },
  { path: "/checkout", name: "checkout", component: AuthCheckout },
  { path: "/sesiones", name: "sesiones", component: SesionesView },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/check-auth`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const data = await response.json();

      if (data.isAuthenticated) {
        next();
      } else {
        next("/admin");
      }
    } catch (error) {
      console.error("Error checking authentication", error);
      next("/admin");
    }
  } else {
    next();
  }
});

export default router;
