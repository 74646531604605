<template>
  <div class="sesiones-container">
    <Navbar />
    <div class="sesiones">
      <div class="header">
      </div>
      <div v-if="loading" class="loading">Cargando packs de sesiones...</div>
      <div v-else>
        <div class="sesion-detalle">
          <div class="sesion-imagen">
            <img
              src="../assets/e7abierto.jpg"
              class="imagen-principal"
              alt="Cabina del solarium"
            />
          </div>
          <div class="sesion-info">
            <h1>Packs de Sesiones de Solarium</h1>
            <p class="descripcion">
              Disfruta de una experiencia de bronceado profesional y segura en
              nuestra cabina de solarium. Cada sesión está diseñada para
              proporcionar un bronceado uniforme y saludable.
            </p>
            <div class="sesion-formulario">
              <label for="opciones-sesiones">Selecciona tu Pack</label>
              <select id="opciones-sesiones" v-model="sesionSeleccionada">
                <option
                  v-for="sesion in sesiones"
                  :key="sesion.id"
                  :value="sesion"
                >
                  {{ sesion.nombre }} - PROXIMAMENTE
                </option>
              </select>
              <button>Añadir al carrito</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import apiClient from "../api";
import Navbar from "../components/NavbarTop.vue";
import Footer from "../components/Footer.vue";
import state from "../state";
//@click="agregarSesionAlCarrito"
export default {
  name: "SesionesSolarium",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const sesiones = ref([]);
    const loading = ref(true);
    const sesionSeleccionada = ref(null);
    const store = useStore();

    const fetchSesiones = async () => {
      try {
        const response = await apiClient.get("/productos/sesiones");
        sesiones.value = response.data;
      } catch (error) {
        console.error("Error al cargar los packs de sesiones.");
      } finally {
        loading.value = false;
      }
    };

    const formatPrice = (value) => {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    };

    const agregarSesionAlCarrito = () => {
      if (sesionSeleccionada.value) {
        const sesionConCantidad = {
          ...sesionSeleccionada.value,
          cantidad: 1, // Asumimos que la cantidad es 1 por defecto
        };
        store.commit("agregarAlCarrito", sesionConCantidad);
        state.agregarAlCarrito(sesionConCantidad);
      }
    };

    fetchSesiones();

    return {
      sesiones,
      loading,
      formatPrice,
      agregarSesionAlCarrito,
      sesionSeleccionada,
    };
  },
};
</script>

<style scoped>
.sesiones-container {
  background-color: #f9f9f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 5em;
}

.sesiones {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.header {
  text-align: center;
  margin-bottom: 40px;
}

.header h1 {
  font-size: 2.5em;
  color: #333;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.loading {
  font-size: 1.5em;
  color: #888;
  text-align: center;
}

.sesion-detalle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.sesion-imagen {
  flex: 1 1 45%;
  margin-bottom: 20px;
}

.imagen-principal {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.sesion-info {
  flex: 1 1 45%;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.sesion-info h2 {
  font-size: 2em;
  margin-bottom: 15px;
  color: #333;
}

.descripcion {
  margin-bottom: 20px;
  font-size: 1.1em;
  color: #555;
}

.sesion-formulario {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sesion-formulario label {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.1em;
}

.sesion-formulario select {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  width: 100%;
  max-width: 300px;
}

.sesion-formulario button {
  background-color: #ffcc00;
  color: #000;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.1em;
}

.sesion-formulario button:hover {
  background-color: #e6b800;
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .header h1 {
    font-size: 2em;
  }

  .sesion-detalle {
    flex-direction: column;
  }

  .sesion-imagen,
  .sesion-info {
    flex: 1 1 100%;
  }

  .sesion-info h2 {
    font-size: 1.5em;
  }

  .descripcion {
    font-size: 1em;
  }

  .sesion-formulario button {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 1.8em;
  }

  .sesion-info h2 {
    font-size: 1.3em;
  }

  .descripcion {
    font-size: 0.9em;
  }

  .sesion-formulario select {
    font-size: 0.9em;
  }

  .sesion-formulario button {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}
</style>
