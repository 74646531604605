// api.js
import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // Si necesitas enviar cookies o cabeceras de autenticación
  headers: {
    'Content-Type': 'application/json'
  }
});

// Agregar un interceptor para incluir el token en cada solicitud
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("adminToken");
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

export default apiClient;
