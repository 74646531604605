<template>
  <div class="carrito">
    <h2>Carrito de Compras</h2>
    <button @click="$emit('close')" class="close-btn">X</button>
    <div v-if="!carrito || carrito.length === 0">El carrito está vacío</div>
    <div v-else>
      <div v-for="item in carrito" :key="item.id" class="carrito-item">
        <img
          :src="item.imagen"
          alt="Imagen del producto"
          class="carrito-imagen"
        />
        <div class="carrito-detalles">
          <h3>{{ item.nombre }}</h3>
          <p>{{ formatPrice(item.precio) }}</p>
          <p>Cantidad: {{ item.cantidad }}</p>
          <button @click="removeFromCarrito(item.id)" class="btn delete">
            Eliminar
          </button>
        </div>
      </div>
      <div class="carrito-total">
        <h3>Total: {{ formatPrice(total) }}</h3>
        <router-link to="/checkout" class="btn">Comprar</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "Carrito",
  setup() {
    const store = useStore();
    const carrito = computed(() => store.state.carrito);

    const formatPrice = (value) => {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    };

    const removeFromCarrito = (id) => {
      store.commit("eliminarDelCarrito", id);
    };

    const total = computed(() => {
      return carrito.value.reduce(
        (acc, item) => acc + item.precio * item.cantidad,
        0
      );
    });

    return { carrito, formatPrice, removeFromCarrito, total };
  },
};
</script>

<style scoped>
.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.carrito {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  overflow-y: auto;
}

.carrito-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.carrito-imagen {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.carrito-detalles {
  flex: 1;
  margin-left: 10px;
}

.carrito-total {
  margin-top: 20px;
  text-align: center;
}

.btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.btn.delete {
  background-color: #dc3545;
}

.btn:hover {
  opacity: 0.9;
}
</style>
