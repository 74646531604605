<template>
  <div class="productos-container">
    <Navbar />
    <div class="productos">
      <div v-if="loading" class="loading">Cargando productos...</div>
      <div v-else class="productos-list">
        <div
          v-for="producto in productos"
          :key="producto.id"
          class="producto-card"
        >
          <img
            :src="producto.imagen"
            alt="Imagen del producto"
            class="producto-imagen"
          />
          <div class="producto-detalles">
            <h2 class="producto-nombre">{{ producto.nombre }}</h2>
            <p class="producto-precio">{{ formatPrice(producto.precio) }}</p>
            <button
              class="producto-boton"
              @click="agregarProductoAlCarrito(producto)"
            >
              Comprar
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import apiClient from "../api";
import Navbar from "../components/NavbarTop.vue";
import Footer from "../components/Footer.vue";
import state from "../state";

export default {
  name: "TodosProductos",
  components: {
    Navbar,
    Footer,
  },

  setup() {
    const productos = ref([]);
    const loading = ref(true);
    const store = useStore();

    const fetchProductos = async () => {
      try {
        const response = await apiClient.get("/productos/");
        productos.value = response.data;
      } catch (error) {
        console.error("Error al cargar los productos.");
      } finally {
        loading.value = false;
      }
    };

    const formatPrice = (value) => {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    };

    const agregarProductoAlCarrito = (producto) => {
      store.commit("agregarAlCarrito", producto);
      state.agregarAlCarrito(producto);
    };

    fetchProductos();

    return { productos, loading, formatPrice, agregarProductoAlCarrito };
  },
};
</script>

<style scoped>
.productos-container {
  background-color: #f5f5f5; /* Color de fondo neutro */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: 7em;
}

.productos {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  text-align: center;
  background-color: #ffffff; /* Fondo blanco para el contenido */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra ligera */
}

.loading {
  font-size: 1.5em;
  color: #888;
}

.productos-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
}

@media (min-width: 1024px) {
  .productos-list {
    grid-template-columns: repeat(4, 1fr); /* 4 columnas en pantallas grandes */
  }
}

.producto-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  background-color: #fff; /* Fondo blanco */
  transition: box-shadow 0.3s ease;
}

.producto-card:hover .producto-boton {
  background-color: #7f5a35; /* Color café al hacer hover sobre la tarjeta */
  color: white;
}

.producto-imagen {
  width: 100%;
  height: 20em; /* Altura fija para las imágenes */
  object-fit: cover; /* Recorta las imágenes para que se ajusten */
  border-radius: 10px;
}

.producto-detalles {
  padding: 10px;
  width: 100%;
  text-align: left;
}

.producto-nombre {
  font-size: 1.2em;
  margin: 10px 0 5px 0;
  font-weight: 300; /* Tipografía más delgada */
}

.producto-precio {
  font-size: 1em;
  margin: 0 0 10px 0;
  font-weight: 200; /* Tipografía más delgada */
}

.producto-rating {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.star {
  font-size: 1.2em;
  color: #ddd;
}

.star.filled {
  color: #ffc107;
}

.producto-boton {
  background-color: #fff; /* Botón blanco */
  color: #000; /* Letras negras */
  border: 1px solid #000; /* Borde negro */
  padding: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: auto;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.producto-boton:hover {
  background-color: #7f5a35; /* Color café al hacer hover sobre el botón */
  color: #fff; /* Letras blancas al hacer hover sobre el botón */
  border: 1px solid #7f5a35; /* Borde café al hacer hover sobre el botón */
}
</style>
