<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
body, html, #app {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  width: 100%;
  overflow-x: hidden;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

router-view {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
