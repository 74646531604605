import { reactive } from 'vue';

const state = reactive({
  carrito: JSON.parse(localStorage.getItem('carrito')) || [],
});

const agregarAlCarrito = (producto) => {
  console.log('Producto a agregar:', producto);
  const existe = state.carrito.find((item) => item.id === producto.id);
  if (existe) {
    existe.cantidad += 1;
    console.log('Producto existente, nueva cantidad:', existe.cantidad);
  } else {
    state.carrito.push({ ...producto, cantidad: 1 });
    console.log('Producto agregado al carrito:', producto);
  }
  localStorage.setItem('carrito', JSON.stringify(state.carrito));
  console.log('Estado actual del carrito:', state.carrito);
};

const removeFromCarrito = (id) => {
  console.log('Producto a eliminar con id:', id);
  state.carrito = state.carrito.filter((item) => item.id !== id);
  localStorage.setItem('carrito', JSON.stringify(state.carrito));
  console.log('Estado actual del carrito después de eliminar:', state.carrito);
};

const clearCarrito = () => {
  console.log('Limpiando carrito');
  state.carrito = [];
  localStorage.setItem('carrito', JSON.stringify(state.carrito));
  console.log('Estado actual del carrito después de limpiar:', state.carrito);
};

export default {
  state,
  agregarAlCarrito,
  removeFromCarrito,
  clearCarrito,
};
