<template>
    <div class="pago-aprobado">
      <h1>¡Pago Aprobado!</h1>
      <p>Tu pago se ha procesado exitosamente. Gracias por tu compra.</p>
      <router-link to="/">Volver al Inicio</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PagoAprobado',
  };
  </script>
  
  <style scoped>
  .pago-aprobado {
    text-align: center;
    padding: 20px;
  }
  </style>
  