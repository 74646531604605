<template>
  <nav class="admin-navbar">
    <router-link to="/admin/dashboard">Dashboard</router-link>
    <router-link to="/admin/add-product">Agregar Producto</router-link>
    <router-link to="/admin/edit-products">Editar Productos</router-link>
    <router-link to="/admin/sales">Ventas</router-link>
    <button @click="logout" class="logout-btn">Cerrar Sesion</button>
  </nav>
</template>

<script>
import apiClient from '../api';

export default {
  name: 'AdminNavbar',
  methods: {
    async logout() {
      try {
        await apiClient.get('/logout');
        this.$router.push('/admin');
      } catch (error) {
        console.error('Error al cerrar sesión:', error);
      }
    }
  }
};
</script>

<style scoped>
.admin-navbar {
  background-color: #007BFF;
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 15px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.admin-navbar a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
}

.admin-navbar a:hover {
  text-decoration: underline;
}

.logout-btn {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
}

.logout-btn:hover {
  text-decoration: underline;
}
</style>
