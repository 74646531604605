<template>
  <nav class="navbar">
    <button class="navbar-toggle" @click="toggleMenu">
      <i class="fas fa-bars"></i>
    </button>
    <div class="navbar-section navbar-left">
      <router-link to="/" @click="toggleMenu">Inicio</router-link>
      <router-link to="/productos" @click="toggleMenu">Productos</router-link>
      <router-link to="/sesiones" @click="toggleMenu">Sesiones</router-link>
      <router-link to="/guias" @click="toggleMenu">Guías</router-link>
      <router-link to="/nosotros" @click="toggleMenu">Nosotros</router-link>
    </div>
    <div class="navbar-section navbar-center">
      <router-link to="/">
        <img class="logo-bali" src="../assets/bali_main.png" alt="Bali Logo" />
      </router-link>
    </div>
    <div class="navbar-section navbar-right">
      <div class="carrito-icon" @click="toggleCarrito">
        <i class="fas fa-shopping-bag"></i>
        <span v-if="carritoCantidad > 0" class="carrito-cantidad">{{
          carritoCantidad
        }}</span>
      </div>
    </div>
    <Carrito v-if="carritoVisible" @close="toggleCarrito" />
    <div v-if="menuVisible" class="navbar-menu">
      <router-link to="/" @click="toggleMenu">Inicio</router-link>
      <router-link to="/productos" @click="toggleMenu">Productos</router-link>
      <router-link to="/sesiones" @click="toggleMenu">Sesiones</router-link>
      <router-link to="/guias" @click="toggleMenu">Guías & Tips</router-link>
      <router-link to="/nosotros" @click="toggleMenu">Nosotros</router-link>
    </div>
  </nav>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Carrito from "./Carrito.vue";

export default {
  name: "NavbarTop",
  components: { Carrito },
  setup() {
    const carritoVisible = ref(false);
    const menuVisible = ref(false);
    const store = useStore();
    const carritoCantidad = computed(() => store.getters.cantidadCarrito);

    const toggleCarrito = () => {
      carritoVisible.value = !carritoVisible.value;
    };

    const toggleMenu = () => {
      menuVisible.value = !menuVisible.value;
    };

    return {
      carritoVisible,
      toggleCarrito,
      carritoCantidad,
      menuVisible,
      toggleMenu,
    };
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #000;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 1em;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-section {
  display: flex;
  align-items: center;
}

.navbar-left {
  flex: 1;
  margin-left: 2em;
}

.navbar-center {
  flex: 1;
  justify-content: center;
  text-align: center;
}

.navbar-right {
  flex: 1;
  justify-content: flex-end;
  margin-right: 3em;
  font-size: 1.25em;
}

.navbar-center img {
  max-height: 5em;
}

.navbar a {
  color: #000;
  text-decoration: none;
  margin: 0 1rem;
  font-size: 1rem;
}

.navbar a:hover {
  text-decoration: underline;
}

.icon {
  margin-left: 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  color: #000;
}

.carrito-icon {
  position: relative;
}

.carrito-cantidad {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: rgb(252, 151, 0);
  color: rgb(0, 0, 0);
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8em;
}

.navbar-toggle {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  background: none;
  border: none;
  margin-right: auto;
  color: #000;
}

.navbar-menu {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1em 2em;
  margin-top: 1em;
  border-radius: 0 0 10px 10px;
  position: absolute;
  top: 2em;
  left: 0;
}

.navbar-menu a {
  margin: 0.5em 0;
  font-size: 1.2rem;
  width: 100%;
  text-align: left;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-section {
    display: none;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-right {
    display: flex;
  }

  .navbar-menu {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-right: 3em;
  }
}
</style>
