import { createStore } from "vuex";

const store = createStore({
  state: {
    user: null,
    carrito: [],
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    agregarAlCarrito(state, producto) {
      const itemEnCarrito = state.carrito.find(
        (item) => item.id === producto.id
      );
      if (itemEnCarrito) {
        itemEnCarrito.cantidad += 1;
      } else {
        state.carrito.push({ ...producto, cantidad: 1 });
      }
    },
    eliminarDelCarrito(state, productoId) {
      state.carrito = state.carrito.filter((item) => item.id !== productoId);
    },
    vaciarCarrito(state) {
      state.carrito = [];
    },
  },
  getters: {
    totalCarrito: (state) => {
      return state.carrito.reduce(
        (total, item) => total + item.precio * item.cantidad,
        0
      );
    },
    cantidadCarrito: (state) => {
      return state.carrito.reduce((total, item) => total + item.cantidad, 0);
    },
  },
});

export default store;
