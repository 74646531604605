<template>
  <Navbar />
  <div class="auth-checkout-container">
    <div class="carrito-resumen">
      <h2>Resumen del Carrito</h2>
      <div v-if="!carrito || carrito.length === 0">El carrito está vacío</div>
      <div v-else>
        <div v-for="item in carrito" :key="item.id" class="carrito-item">
          <img
            :src="item.imagen"
            alt="Imagen del producto"
            class="carrito-imagen"
          />
          <div class="carrito-detalles">
            <h3>{{ item.nombre }}</h3>
            <p>{{ formatPrice(item.precio) }}</p>
            <p>Cantidad: {{ item.cantidad }}</p>
          </div>
        </div>
        <div class="carrito-total">
          <h3>Total: {{ formatPrice(total) }}</h3>
        </div>
      </div>
    </div>
    <div class="vertical-separator"></div>
    <div class="auth-checkout">
      <h2>Checkout</h2>
      <div v-if="step === 1">
        <div v-if="!isLoggedIn">
          <div v-if="authMode === 'login'">
            <h3>Iniciar Sesión</h3>
            <form @submit.prevent="login">
              <input
                v-model="email"
                type="email"
                placeholder="Email"
                required
                class="inputText"
              />
              <input
                v-model="password"
                type="password"
                placeholder="Contraseña"
                required
                class="inputText"
              />
              <div class="createOrLoginBtns">
                <button type="submit" class="btn" :disabled="loading">
                  Iniciar Sesión
                </button>
                <button
                  @click="toggleAuthMode"
                  type="button"
                  class="btn btnRgt"
                >
                  Crear Cuenta
                </button>
              </div>
            </form>
          </div>
          <div v-else>
            <h3>Crear Cuenta</h3>
            <form @submit.prevent="createAccount">
              <input
                v-model="newUser.name"
                type="text"
                placeholder="Nombre"
                required
                class="inputText"
              />
              <input
                v-model="newUser.email"
                type="email"
                placeholder="Email"
                required
                class="inputText"
              />
              <input
                v-model="newUser.telefono"
                type="tel"
                placeholder="Teléfono"
                required
                class="inputText"
              />
              <input
                v-model="newUser.direccion"
                type="text"
                placeholder="Dirección"
                required
                class="inputText"
              />
              <input
                v-model="newUser.password"
                type="password"
                placeholder="Contraseña"
                required
                class="inputText"
              />
              <div class="createOrLoginBtns">
                <button type="submit" class="btn" :disabled="loading">
                  Crear Cuenta
                </button>
                <button
                  @click="toggleAuthMode"
                  type="button"
                  class="btn btnRgt"
                >
                  Iniciar Sesión
                </button>
              </div>
            </form>
          </div>
          <h3>Comprar sin Cuenta</h3>
          <form @submit.prevent="guestCheckout">
            <input
              v-model="guest.nombre"
              type="text"
              placeholder="Nombre"
              required
              class="inputText"
            />
            <input
              v-model="guest.email"
              type="email"
              placeholder="Email"
              required
              class="inputText"
            />
            <input
              v-model="guest.telefono"
              type="tel"
              placeholder="Teléfono"
              required
              class="inputText"
            />
            <input
              v-model="guest.direccion"
              type="text"
              placeholder="Dirección"
              required
              class="inputText"
            />
            <button type="submit" class="btn" :disabled="loading">
              Comprar
            </button>
          </form>
        </div>
      </div>
      <div v-else-if="step === 2">
        <h3>Seleccione Método de Entrega</h3>
        <form @submit.prevent="proceedToPayment">
          <div class="form-group">
            <input
              type="radio"
              id="storePickup"
              value="store"
              v-model="deliveryMethod"
              required
            />
            <label for="storePickup">Retiro en Tienda</label>
          </div>
          <div v-if="deliveryMethod === 'store'" class="form-group">
            <label for="storeLocation">Seleccione la tienda:</label>
            <select id="storeLocation" v-model="storeLocation" required>
              <option value="tienda1">Pueblito Siglo XXI</option>
            </select>
          </div>
          <button type="submit" class="btn" :disabled="loading">
            Continuar
          </button>
        </form>
      </div>
      <div v-if="loading" class="loading-indicator">Procesando...</div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import apiClient from "../api";
import Navbar from "../components/NavbarTop.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "AuthCheckout",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const store = useStore();
    const email = ref("");
    const password = ref("");
    const isLoggedIn = ref(false);
    const step = ref(1);
    const deliveryMethod = ref("store"); // Preselecciona "store"
    const storeLocation = ref("tienda1"); // Preselecciona "tienda1"
    const loading = ref(false);

    const authMode = ref("login");

    const newUser = ref({
      name: "",
      email: "",
      telefono: "",
      direccion: "",
      password: "",
    });

    const guest = ref({
      nombre: "",
      email: "",
      telefono: "",
      direccion: "",
    });

    const carrito = computed(() => store.state.carrito);
    const total = computed(() => {
      return carrito.value.reduce(
        (acc, item) => acc + item.precio * item.cantidad,
        0
      );
    });

    const formatPrice = (value) => {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    };

    const login = async () => {
      loading.value = true;
      try {
        const response = await apiClient.post("/auth/login", {
          email: email.value,
          password: password.value,
        });
        store.commit("setUser", response.data.user);
        isLoggedIn.value = true;
        step.value = 2;
      } catch (error) {
        alert("Error al iniciar sesión");
      } finally {
        loading.value = false;
      }
    };

    const createAccount = async () => {
      loading.value = true;
      try {
        const response = await apiClient.post("/auth/register", {
          nombre: newUser.value.name,
          email: newUser.value.email,
          telefono: newUser.value.telefono,
          direccion: newUser.value.direccion,
          password: newUser.value.password,
        });
        store.commit("setUser", response.data.user);
        isLoggedIn.value = true;
        step.value = 2;
      } catch (error) {
        alert("Error al crear cuenta");
      } finally {
        loading.value = false;
      }
    };

    const guestCheckout = async () => {
      step.value = 2;
    };

    const proceedToPayment = async () => {
      loading.value = true;
      const reference = "REF-" + Date.now();
      const metodo_entrega = deliveryMethod.value;
      const direccion = metodo_entrega === "store" ? "" : "";
      const ciudad = metodo_entrega === "store" ? "" : "";

      const detalles = carrito.value.map((item) => ({
        id_producto: item.id,
        cantidad: item.cantidad,
        precio_unitario: item.precio,
      }));

      try {
        const response = await apiClient.post("/payment/create-session", {
          amount: total.value,
          reference,
          carrito: detalles,
          cliente: store.state.user || guest.value,
          metodo_entrega,
          direccion,
          ciudad,
          isGuest: !isLoggedIn.value,
        });

        if (response.data.processUrl) {
          window.location.href = response.data.processUrl;
        } else {
          alert("Error creando la venta");
        }
      } catch (error) {
        alert("Error en la transacción");
      } finally {
        loading.value = false;
      }
    };

    const toggleAuthMode = () => {
      authMode.value = authMode.value === "login" ? "register" : "login";
    };

    return {
      email,
      password,
      newUser,
      guest,
      isLoggedIn,
      login,
      createAccount,
      guestCheckout,
      proceedToPayment,
      carrito,
      total,
      formatPrice,
      step,
      deliveryMethod,
      storeLocation,
      authMode,
      toggleAuthMode,
      loading,
    };
  },
};
</script>

<style scoped>
.auth-checkout-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 7em;
}

.carrito-resumen {
  width: 45%;
}

.carrito-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.carrito-imagen {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}
.createOrLoginBtns .btnRgt {
  margin-left: 1em;
  background-color: #28ce65;
}
.carrito-detalles {
  flex: 1;
  margin-left: 10px;
}

.carrito-total {
  margin-top: 20px;
  text-align: center;
}

.vertical-separator {
  width: 1px;
  background-color: #ddd;
  margin: 0 20px;
}

.auth-checkout {
  width: 45%;
}

form {
  margin-bottom: 20px;
}

input,
select {
  display: block;
  box-sizing: border-box;
}

.inputText {
  margin-bottom: 10px;
  padding: 10px;
  width: 30em;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-group input[type="radio"] {
  margin-right: 10px;
  margin-top: 0;
}

.btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  opacity: 0.9;
}

.loading-indicator {
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}
</style>
