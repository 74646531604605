<template>
    <div class="pago-rechazado">
      <h1>Pago Rechazado</h1>
      <p>Lo sentimos, tu pago no pudo ser procesado. Por favor, intenta nuevamente.</p>
      <router-link to="/">Volver al Inicio</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PagoRechazado',
  };
  </script>
  
  <style scoped>
  .pago-rechazado {
    text-align: center;
    padding: 20px;
  }
  </style>
  